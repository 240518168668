import { useResources } from 'context/resource-provider'
import { ILog } from 'interfaces/ILog'
import React, { useEffect, useState } from 'react'
import LogService from 'services/LogService'
import { DeleteConfirmation } from '../base/DeleteConfirmation'
import { LogsPage } from './LogsPage'

interface LogsControllerProps {
  path?: string
}

export function LogsController(props: LogsControllerProps) {
  const { logs, setLogs, setMessage } = useResources()
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState('')
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [order, setOrder] = useState<'ascending' | 'descending'>('ascending');
  const [sort, setSort] = useState<string>('created_at');
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [deleteItem, setDeleteItem] = useState<ILog>(null)
  const [dates, setDates] = useState<string>('');
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);

  function reloadLogs() {
    setIsLoading(true)
    Promise.all([
      LogService.getLogs(
        activePage - 1,
        limit,
        search,
        sort,
        order === 'ascending' ? '-1' : '1',
        from,
        to
      ),
      LogService.count(search, from, to),
    ])
      .then(([logs, { count }]) => {
        setLogs(logs);
        const lastPage = Math.ceil(count / limit);
        setTotalPages(lastPage);
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    reloadLogs();
  }, [activePage, limit, search, order, from, to])

  useEffect(() => {
    const deleteLogs = logs.filter(log => log.checked === true);
    const deleteIds = deleteLogs.map(log => log._id);
    setDeleteList(deleteIds);
  }, [logs]);

  function handleItemDelete(log: ILog, e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setDeleteItem(log);
  }

  function handleDeleteFromTo(log: ILog) {
    log._id = 'from-to'
    log.machine_name = `${search} ${from} - ${to}`;
    setDeleteItem(log);
  }

  async function handleConfirmDelete() {
    let response;
    switch (deleteItem._id) {
      case 'from-to':
        response = await LogService.deleteLogsFromTo(search, from, to);
        setMessage({
          status: response?.ok === 1,
          text: response?.ok === 1 ? `Deleted logs from ${from} to ${to}` : "Failed to delete logs.",
          isNew: true
        });
        resetStatesAfterDelete();
        break;

      case 'All':
        response = await LogService.deleteAll();
        setMessage({
          status: response?.ok === 1,
          text: response?.ok === 1 ? "Deleted All Logs" : "Failed to delete logs",
          isNew: true
        });
        resetStatesAfterDelete();
        break;

      case "Selected":
        setIsLoading(true);
        response = await LogService.deleteLogsList(deleteList);
        setMessage({
          status: response?.ok === 1,
          text: response?.ok === 1 ? "Deleted selected logs" : "Failed to delete selected logs.",
          isNew: true
        });
        resetStatesAfterDelete();
        break;

      default:
        response = await LogService.deleteLog(deleteItem._id);
        setMessage({
          status: response?.ok === 1,
          text: response?.ok === 1 ? "Deleted Logs" : "Failed to delete logs.",
          isNew: true
        });
        resetStatesAfterDelete();
    }

    function resetStatesAfterDelete() {
      setSearch('');
      handleClearDate();
      setDeleteItem(null);
      setDeleteList([]);
      setSearch('');
      setResetSearch(!resetSearch);
    }
  }

  function handleDeleteSelected() {
    const tempLog = {} as ILog;
    tempLog._id = "Selected";
    setDeleteItem(tempLog);
  }

  function handleDeleteAll() {
    const tempLog = {} as ILog;
    tempLog._id = "All";
    setDeleteItem(tempLog);
  }

  const handleSearchChange = (s: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => {
      setSearch(s);
      setActivePage(1);
    }, 200)
    setTimeoutId(id)
  }

  const refreshLogs = () => {
    setSearch('');
    reloadLogs();
  }

  const handlePaginationChange = (page: number) => {
    setActivePage(page)
  }

  const hanldeSort = (title: string) => {
    setSort(title);
    setOrder(order === 'ascending' ? 'descending' : 'ascending');
  }

  const handleLimitChange = perPage => {
    setLimit(perPage)
    setActivePage(1)
  }

  const onFromChange = (e, { value }) => {
    setDates(value);
    const range = value.split(' - ');
    setFrom(range[0]);
    setTo(range[1]);
  }

  const handleClearDate = () => {
    setSearch('');
    setFrom('');
    setTo('');
    setDates('');
  }

  const handleExportCSV = async () => {
    const logs = await LogService.getLogsCSV(
      0,
      search,
      sort,
      order === 'ascending' ? '-1' : '1',
      from,
      to
    );
  }

  function handleSelect(log: ILog) {
    const logsCopy = [...logs];
    log.checked = log.checked ? false : true;
    const selectedLogIndex = logsCopy.findIndex(logSearched => logSearched._id === log._id);
    logsCopy[selectedLogIndex] = log;
    setLogs(logsCopy);
  }

  function handleSelectAll() {
    const newState = selectAll ? false : true;
    const logsCopy = [...logs];
    setLogs(logsCopy.map(log => {
      log.checked = newState;
      return log;
    }));
    setSelectAll(newState);
  }

  return (
    <>
      <DeleteConfirmation
        title={(deleteItem ? deleteItem.machine_name || deleteItem._id : '') + " log(s)"}
        open={deleteItem != null}
        onExit={() => setDeleteItem(null)}
        onDelete={handleConfirmDelete}
      />
      <LogsPage
        logs={logs}
        onExportCSV={handleExportCSV}
        onItemDelete={handleItemDelete}
        onDeleteMany={handleDeleteFromTo}
        onDeleteAll={handleDeleteAll}
        onDeleteSelected={handleDeleteSelected}
        onLogSelect={handleSelect}
        someSelected={deleteList.length !== 0}
        selectAll={handleSelectAll}
        isLoading={isLoading}
        onPaginationChange={handlePaginationChange}
        activePage={activePage}
        totalPages={totalPages}
        limit={limit}
        onLimitChange={handleLimitChange}
        onSearchChange={handleSearchChange}
        order={order}
        sortTitle={sort}
        onSort={hanldeSort}
        datesRange={dates}
        onDatesChange={onFromChange}
        onClearDate={handleClearDate}
        isDisabled={from === '' && search === '' || logs.length === 0}
        searchValue={search}
        reloadLogs={refreshLogs}
        resetSearch={resetSearch}
      />
    </>
  )
}
